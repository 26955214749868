import { ref, reactive, computed, h } from "vue";
import { useStore } from "vuex";
import {
  dealerTaskList,
  exportDealerListQueryResult
} from "@/API/rv/rvSplitTask";
import { Moment } from "moment";
import {
  TaskStatusList,
  TaskCategoryListCn,
} from "@/views/RetailValidation/Communication/dictionary";
import {downloadFromStream} from "@/utils/payment/downloadFile";
const useTaskDistributionOverview = () => {
  const statusList = ref(TaskStatusList);
  const categoryList = ref(TaskCategoryListCn);
  const useFormatExpirationDate = () => {
    return ({ text }: { text: number | string }) => {
      const _text = text ? text : "无要求";
      return h("span", _text);
    };
  };

  const { state } = useStore();

  // 将list转化为map
  const getMap = (list: any[]) => {
    return list?.reduce((prev, cur) => {
      prev[cur.value] = cur.name;
      return prev;
    }, {});
  };
  const column = ref([
    { title: "任务名称", dataIndex: "taskNameCn", width: 168 },
    {
      title: "任务种类",
      dataIndex: "category",
      width: 168,
      customRender: ({ text }: any) => {
        return h("span", getMap(categoryList.value)[text]);
      },
    },
    {
      title: "任务描述",
      dataIndex: "taskDescription",
      width: 168
    },
    { title: "任务发布日期", dataIndex: "releaseTime", width: 168 },
    {
      title: "任务截止日期",
      dataIndex: "expirationDate",
      width: 168,
      customRender: useFormatExpirationDate(),
    },
    {
      title: "经销商反馈",
      dataIndex: "feedbackDealer",
      align: 'center',
      slots: {
        customRender: 'feedback'
      },
      width: 168
    },
    {
      title: "操作",
      width: 150,
      slots: { customRender: "operation" },
      align: "center",
    },
  ]);
  const taskPeriod = ref<Moment[]>([]);
  const dateFormat = ref("YYYY/MM/DD");
  const query = reactive({
    beginDate: "",
    endDate: "",
    taskNameCn: "",
    category: "-1",
  });
  const dataRes = ref();
  const data = computed(() => {
    return dataRes.value ? dataRes.value : [];
  });
  const fetchTableData = () => {
    const params = {
      isTimeData: "-1",
      beginDate: "",
      endDate: "",
      taskNameCn: query.taskNameCn,
      category: query.category,
      buName: "",
      dealerCode: state.user.organization.entityCode,
      taskStatus: '-1'
    };
    if (taskPeriod.value.length) {
      params.isTimeData = "1";
      params.beginDate = taskPeriod.value[0].format(dateFormat.value);
      params.endDate = taskPeriod.value[1].format(dateFormat.value);
    }
    dealerTaskList(params).then((res: any) => {
      dataRes.value = res.filter((item: any) => item.detailId !== '');
    });
  };
  const exportQueryResult = async () => {
    const params = {
      isTimeData: "-1",
      beginDate: "",
      endDate: "",
      taskNameCn: query.taskNameCn,
      category: query.category,
      buName: "",
      dealerCode: state.user.organization.entityCode,
      taskStatus: '-1'
    };
    if (taskPeriod.value.length) {
      params.isTimeData = "1";
      params.beginDate = taskPeriod.value[0].format(dateFormat.value);
      params.endDate = taskPeriod.value[1].format(dateFormat.value);
    }
    const res = await exportDealerListQueryResult(params)
    downloadFromStream(res)
  }
  return {
    query,
    taskPeriod,
    dateFormat,
    column,
    data,
    fetchTableData,
    statusList,
    categoryList,
    exportQueryResult
  };
};
export default useTaskDistributionOverview;
