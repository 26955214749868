
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import useDataDocumentReceive from "@/hooks/rv/communication/useDataDocumentReceive";
import {
  dealerViewFeedback,
  dealerAddFeedback,
  dealerEditFeedback,
  dealerDocExport,
  dealerDataExport,
} from "@/API/rv/rvSplitTask";
import { message } from "ant-design-vue";
import {
  downloadFromStream,
  downloadFromUrl,
} from "@/utils/payment/downloadFile";
import { Modal } from "ant-design-vue";

enum Type {
  ADD,
  EDIT,
}

export default defineComponent({
  name: "DataDocumentReceive",
  setup() {
    const { state } = useStore();

    const feadbackModalvisible = ref(false);
    const feadbackModalContent = ref("");

    const feedbackEditorModalVisible = ref(false);
    const feedbackEditorModalContent = ref("");

    // 提交反馈时选中数据Id
    const id = ref<number>();
    const type = ref<Type>(Type.ADD);

    const {
      query,
      taskPeriod,
      dateFormat,
      column,
      data,
      fetchTableData,
      categoryList,
      exportQueryResult,
    } = useDataDocumentReceive();

    const search = () => {
      fetchTableData();
    };
    const init = () => {
      search();
    };
    init();

    const handleViewFeedback = (record: any) => {
      if (record.feedbackDealer === "2") {
        return;
      }
      dealerViewFeedback(record.detailId).then((res: any) => {
        feadbackModalContent.value = res;
        feadbackModalvisible.value = true;
      });
    };

    const handleToAddFeedback = (record: any) => {
      id.value = record.detailId as number;
      type.value = Type.ADD;
      feedbackEditorModalContent.value = "";
      feedbackEditorModalVisible.value = true;
    };

    const handleToEditFeedback = async (record: any) => {
      id.value = record.detailId as number;
      type.value = Type.EDIT;
      feedbackEditorModalContent.value = (await dealerViewFeedback(
        record.detailId
      )) as string;
      feedbackEditorModalVisible.value = true;
    };

    const handleExport = async (record: any) => {
      if (record.category === "1") {
        const data = await dealerDataExport(
          record.id,
          state.user.organization.entityCode
        );
        downloadFromStream(data);
      } else {
        const data = await dealerDocExport(record.detailId);
        downloadFromUrl(data.downloadUrl);
      }
    };

    const handleSaveFeedback = async () => {
      if (id.value) {
        if (type.value === Type.ADD) {
          await dealerAddFeedback(id.value, feedbackEditorModalContent.value);
          message.success("添加成功");
        } else {
          await dealerEditFeedback(id.value, feedbackEditorModalContent.value);
          message.success("保存成功");
        }
        feedbackEditorModalVisible.value = false;
        search();
      }
    };

    const handleForbidAddFeedback = () => {
      Modal.confirm({
        content: "已过任务截止日期，不能进行编辑！",
        okText: "确认",
        cancelText: "取消",
      });
    };

    return {
      feadbackModalvisible,
      feadbackModalContent,
      feedbackEditorModalVisible,
      feedbackEditorModalContent,
      query,
      taskPeriod,
      dateFormat,
      column,
      data,
      search,
      handleViewFeedback,
      handleToAddFeedback,
      handleToEditFeedback,
      handleSaveFeedback,
      categoryList,
      handleExport,
      isPagination: false,
      exportQueryResult,
      handleForbidAddFeedback,
    };
  },
  components: {
    TableWrapper,
  },
});
